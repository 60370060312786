const IconArrowRightCalendar = props => (
	<svg
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="flip-if-rlt"
	>
		<path
			d="M11.4141 22.12L17.5207 16L11.4141 9.88L13.2941 8L21.2941 16L13.2941 24L11.4141 22.12Z"
			fill="currentColor"
		/>
	</svg>
);
export default IconArrowRightCalendar;
