/* eslint-disable react/no-children-prop */
import { memo } from "react";
import PropTypes from "prop-types";
import PassengersCounterDisplay from "app/pages/.shared/PassengersCounterDisplay";
import { FormattedMessage, FormattedPlural } from "react-intl";
import "./OccupanciesDisplayLabel.scss";

const OccupanciesDisplayLabel = ({ occupancies = [], showPrefix = true }) => {
	let adultTotal = 0;
	let childTotal = 0;
	const roomTotal = occupancies.length;

	occupancies.forEach(occupancy => {
		adultTotal += occupancy.adults;
		childTotal += occupancy.children;
	});

	const prefix = (
		<FormattedPlural
			value={roomTotal}
			one={
				<FormattedMessage
					values={{
						count: roomTotal,
					}}
					id="general.room.count.label.singular"
				/>
			}
			other={
				<FormattedMessage
					values={{
						count: roomTotal,
					}}
					id="general.room.count.label.plural"
				/>
			}
		/>
	);

	return (
		<PassengersCounterDisplay
			adults={adultTotal}
			children={childTotal}
			{...showPrefix && { prefix }}
		/>
	);
};

OccupanciesDisplayLabel.propTypes = {
	occupancies: PropTypes.array,
	showPrefix: PropTypes.bool,
};

export default memo(OccupanciesDisplayLabel);
