const SvgComponent = props => (
	<svg {...props} viewBox="0 0 20 20">
		<path
			xmlns="http://www.w3.org/2000/svg"
			d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgComponent;
