const IconCalendarFlightTakeOff = props => (
	<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g id="flight_takeoff">
			<path
				id="Vector"
				d="M2.03473 12.4766L14.7014 12.4766V13.8099L2.03473 13.8099L2.03473 12.4766ZM15.0814 6.23661C14.9414 5.70328 14.3881 5.38328 13.8547 5.52994L10.3147 6.47661L5.71473 2.18994L4.42806 2.52994L7.18806 7.30994L3.87473 8.19661L2.56139 7.16994L1.59473 7.42994L3.32139 10.4233L14.3681 7.46994C14.9081 7.31661 15.2214 6.76994 15.0814 6.23661Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default IconCalendarFlightTakeOff;
