const IconArrowLeftCalendar = props => (
	<svg
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className="flip-if-rlt"
	>
		<g id="Icon/Search">
			<path
				id="Vector"
				d="M21.2946 22.12L15.1879 16L21.2946 9.88L19.4146 8L11.4146 16L19.4146 24L21.2946 22.12Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default IconArrowLeftCalendar;
